import React, { Component } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/molecules/SEO';
import Pagination from '../components/organisms/Pagination';
import TrainingPreview from '../components/molecules/TrainingPreview';
import PreHead from '../components/molecules/PreHead';
import Container from '../components/atoms/Container';
import styled from 'styled-components';
import TrainingListHeader from '../components/molecules/TrainingListHeader';

const Holder = styled.article`
  margin: 10rem 0;
  h1 {
    text-align: center;
    margin-bottom: 6rem;
  }
`;

class TrainingCalendar extends Component {

  render() {

    const {data, pageContext} = this.props;
    const {contentfulGlobalElements, allContentfulTrainingDate, allContentfulTraining} = data;
    const { trainingHeroImage } = contentfulGlobalElements;

    return (
      <>
        <SEO title="Training Calender"/>
        <PreHead text="Training Calender" fluidImage={trainingHeroImage.fluid}/>
        <Container>
          <Holder>
            <TrainingListHeader/>
            {allContentfulTrainingDate.nodes.map( date => (
              <TrainingPreview key={date.id} date={date} allTraining={allContentfulTraining}/>
            ) )}
            <Pagination base="training-calendar" pageContext={pageContext}/>
          </Holder>
        </Container>
      </>
    )
  }
}

export default TrainingCalendar;

export const trainingArchiveQuery = graphql`
    query trainingArchiveQuery($skip: Int!, $limit: Int!) {
        allContentfulTrainingDate(
            sort: {fields: [date], order: ASC}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                id
                date(formatString: "DD.MM.YY")
                location
            }
        }
        allContentfulTraining {
            nodes {
                id
                title
                slug
                duration
                availableOnDemand
                excerpt {
                    excerpt
                }
                vendor{
                    title
                    logo {
                        fluid(maxWidth: 500) {
                            base64
                            sizes
                            src
                            srcSet
                            aspectRatio
                        }
                    }
                    slug
                }
                dates {
                    id
                }
            }
        }
        contentfulGlobalElements(title: {eq: "Global elements"}) {
            trainingHeroImage {
                fluid(maxWidth: 3000) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                }
            }
        }
    }
`;
